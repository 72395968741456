import './SettingsCentersContent.less'

import { Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclAction, AclFeature, CenterData, CentersSorter, SorterOrder, fetchCenters } from '../../../requests'
import { UserContext } from '../../auth'
import { DatacIcon, DatacMessage, DatacTitle } from '../../common'
import { SettingsLayout } from '../SettingsLayout'
import { SettingsCentersAddModal } from './SettingsCentersAddModal'
import { SettingsCentersArchiveModal } from './SettingsCentersArchiveModal'
import { SettingsCentersTable } from './SettingsCentersTable'

const pageSize = 20
const defaultSorter: CentersSorter = { field: 'name', order: SorterOrder.Ascend }

export const SettingsCentersContent: React.FC = () => {
  const intlCenters = useScopedIntl('settings.centers')
  const [centers, setCenters] = useState<CenterData[]>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [allCount, setAllCount] = useState(0)
  const [isFetchingCenters, setIsFetchingCenters] = useState(false)
  const [isAddModalOpened, setIsAddModalOpened] = useState(false)
  const [centerToArchive, setCenterToArchive] = useState<CenterData>(null)
  const [sorter, setSorter] = useState<CentersSorter>(null)
  const { user } = useContext(UserContext)
  const userCan = user.canDo(AclFeature.Centers)
  const intl = useScopedIntl('')
  const [filters, setFilters] = useState<Record<string, string[]>>()
  const [search, setSearch] = useState<string>(null)
  const [countries, setCountries] = useState<string[]>([])

  useEffect(() => {
    fetchCentersPage(0, sorter, search, filters)
  }, [search, filters])

  const onPageChange = (page: number) => {
    fetchCentersPage(page - 1, sorter, search, filters)
  }

  const fetchCentersPage = (page: number, sorter: CentersSorter, search: string, filters: Record<string, string[]>) => {
    setIsFetchingCenters(true)
    return fetchCenters(
      { options: { limit: pageSize, offset: page * pageSize, sorter: sorter || defaultSorter, search, filters } },
      {
        onSuccess: ({ centers, allCentersCount, countries }) => {
          setCenters(centers)
          setCountries(countries)
          setAllCount(allCentersCount)
          setIsFetchingCenters(false)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsFetchingCenters(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onSorterChange = (tableSorter: { field: keyof CenterData; order: SorterOrder }) => {
    const sorterChanged =
      (!sorter && tableSorter.order) ||
      (sorter && (tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order))
    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchCentersPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const refreshCentersList = () => {
    fetchCentersPage(currentPage - 1, sorter, search, filters)
  }

  const onFiltersChange = (filters: Record<string, string[]>) => {
    setFilters(filters)
  }

  return (
    <SettingsLayout>
      <div className="settings-centers-content">
        <div className="settings-users-content__header">
          <DatacTitle type="h2">{intlCenters('title')}</DatacTitle>
          {userCan(AclAction.Edit) && (
            <Button
              className="settings-centers-content__header-button settings-centers-content__add-button"
              type="primary"
              size="large"
              onClick={() => setIsAddModalOpened(true)}
            >
              <DatacIcon type="white" name="plus" size="big" />
              {intlCenters('add_center')}
            </Button>
          )}
        </div>
        {!!centers && (
          <SettingsCentersTable
            centers={centers}
            currentPage={currentPage}
            onPageChange={onPageChange}
            totalCount={allCount}
            pageSize={pageSize}
            disabled={isFetchingCenters}
            onSorterChange={onSorterChange}
            onArchive={setCenterToArchive}
            onFiltersChange={onFiltersChange}
            filters={filters}
            onSearchChange={setSearch}
            search={search}
            countries={countries}
          />
        )}
      </div>
      {userCan(AclAction.Edit) && (
        <SettingsCentersAddModal
          isOpened={isAddModalOpened}
          onClose={() => setIsAddModalOpened(false)}
          onCenterAdded={refreshCentersList}
        />
      )}
      {userCan(AclAction.Edit) && (
        <SettingsCentersArchiveModal
          isOpened={!!centerToArchive}
          center={centerToArchive}
          onClose={() => setCenterToArchive(null)}
          onCenterArchived={refreshCentersList}
        />
      )}
    </SettingsLayout>
  )
}
