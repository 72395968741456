import './SettingsCentersTable.less'

import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CenterData, SorterOrder } from '../../../../requests'
import { TableRecord, createTableRecords } from '../../../../utils'
import { DatacPaginationItem, DatacTable, DatacTableSearchAndFilters } from '../../../common'
import { getColumns, searchAndFilterOptions } from './SettingsCentersTableConfig'

interface Props {
  onArchive?: (center: CenterData) => void
  currentPage: number
  onPageChange: (page: number) => void
  totalCount: number
  disabled?: boolean
  pageSize: number
  centers: CenterData[]
  onSorterChange: (tableSorter: { field: keyof CenterData; order: SorterOrder }) => void
  onFiltersChange: (filters: Record<string, string[]>) => void
  filters: Record<string, string[]>
  onSearchChange: (value: string) => void
  search: string
  countries: string[]
}

export const SettingsCentersTable: React.FC<Props> = ({
  onArchive,
  currentPage,
  onPageChange,
  totalCount,
  disabled = false,
  pageSize,
  centers,
  onSorterChange,
  onFiltersChange,
  filters,
  onSearchChange,
  search,
  countries
}) => {
  const [studyCenters, setStudyCenters] = useState<TableRecord<CenterData>[]>(null)
  const intl = useScopedIntl('')
  const intlCenters = useScopedIntl('settings.centers')

  useEffect(() => {
    setStudyCenters(createTableRecords<CenterData>(centers))
  }, [centers])

  const columns = getColumns({
    columnNames: {
      name: intlCenters('column.name'),
      country: intlCenters('column.country'),
      abbreviation: intlCenters('column.abbreviation'),
      actions: intl('common.actions')
    },
    onArchive
  })

  return (
    <div className="settings-centers-table">
      <DatacTableSearchAndFilters
        onSearchChange={value => onSearchChange(value)}
        onFiltersChange={onFiltersChange}
        isSearching={disabled}
        searchAndFilterOptions={searchAndFilterOptions(countries, intl)}
        initialSearchValue={search}
        initialFilterValues={filters}
      />
      <DatacTable
        dataSource={studyCenters}
        columns={columns}
        rowSelection={undefined}
        scroll={{ x: 700 }}
        pagination={{
          current: currentPage,
          position: ['bottomCenter'],
          itemRender: DatacPaginationItem,
          onChange: onPageChange,
          total: totalCount,
          showSizeChanger: false,
          disabled,
          pageSize
        }}
        onChange={(pagination, filters, sorter) =>
          onSorterChange(sorter as { field: keyof CenterData; order: SorterOrder })
        }
      />
    </div>
  )
}
